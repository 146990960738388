<template>
  <div class="editor-after">
    <div class="img-width">
      <div style="position: relative; display: flex;" v-for="(item, index) in srcList" :key="index">
        <el-image class="plan-image" :src="item" fit="cover" :preview-src-list="srcList"></el-image>
        <i class="el-icon-remove remove-icon" @click="delPicList(index)"></i>
      </div>
      <el-upload
        ref="uploadRef"
        :limit="1"
        :headers="myHeaders"
        :action="upload"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
        :on-success="uploadSuccess"
      >
        <div class="upload" v-show="srcList.length < 1">
          <i class="el-icon-plus"></i>
        </div>
      </el-upload>
    </div>
    <span class="hint">{{ hintText }}</span>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { array } from '@/utils/jszip';

export default {
  data() {
    return {
      srcList: [],
      myHeaders: {
        jtoken: getToken(),
        clientType: "ADMIN",
        userType: "USER_DOCTOR"
      },
      upload: "/ohealth/api/v1/system/common/uploadFile"
    };
  },

  props: {
    hintText: {
      type: String,
      default: ""
    },
    srcListProps: {
      type: Array
    },
    srcProps: {
      type: String,
      default: ''
    },
  },
  watch: {
    srcListProps(newValue) {
      this.srcList = newValue;
    },
    srcProps(newValue) {
      if(newValue){
        this.srcList = [newValue];
      }else{
        this.srcList = [];
      }
    }
  },

  methods: {
    // 重置数据
    resetData() {
      this.srcList = [];
      this.$refs.uploadRef.clearFiles();
    },

    delPicList(index) {
      this.$refs.uploadRef.clearFiles();
      this.srcList.splice(index, 1);
      this.$emit("clearData");
    },

    // 上传成功后
    uploadSuccess(response) {
      let { code, data, msg } = response;
      if (code == 200) {
        this.srcList.push(data.imgUrl);
        this.$emit("uploadSuccess", data.imgUrl);
      } else {
        this.$message.error(msg);
      }
    },

    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.$message.warning("告知书图片大小不能超过 20MB!");
      }
      return isLt2M;
    }
  }
};
</script>

<style scoped lang="scss">
/* 上传图片 */
.editor-after {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .hint {
    font-size: 12px;
    color: #f53838;
    line-height: normal;
  }

  .img-width {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;

    .plan-image,
    .upload {
      width: 100px;
      height: 100px;
      border-radius: 4px;
      margin: 6px;
      border: 1px solid #cbcbd0;
      box-sizing: border-box;
    }

    .remove-icon {
      position: absolute;
      top: -5px;
      right: -4px;
      z-index: 99;
      font-size: 18px;
      color: rgb(247, 74, 74);
      border: 1px solid #fff;
      border-radius: 50%;
      background: #fff;
      cursor: pointer;
    }

    .upload {
      border: 1px dashed #cbcbd0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      color: #999999;
      cursor: pointer;

      &:hover {
        border: 1px dashed #409eff;
        background: rgb(246, 250, 255);
        color: #409eff;
        transition: all 0.3s;
      }
    }
  }
}
</style>
